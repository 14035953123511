:root {
    --white: #e0dbdb;
    --red: #dc0101;
    --graphite: #252525
}

body {
    font-family: 'Inter', sans-serif;
    height: 100vh;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Oswald', sans-serif;
}

.text-danger {
    color: var(--red) !important;
}

.btn-danger {
    background-color: var(--red) !important;
}

.btn-contato-whatsapp {
    background-color: #00bb2d;
    color: #fff;
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
}

#menu {
    position: sticky;
    top: 0;
    background-color: rgba(255, 255, 255, .8);
    backdrop-filter: saturate(180%) blur(10px);
    z-index: 9999;
}

header nav a.nav-link {
    text-decoration: none;
    color: #000;
    transition: all 200ms ease-out;
    outline: 0 !important;
}

header nav a.nav-link:hover, header nav a.nav-link:focus {
    color: var(--red);
}

.py-10 {
    padding: 50px 0;
}

.px-10 {
    padding: 0 250px;
}

.services .img-item-service {
    max-width: 200px;
    height: 200px;
    align-self: center;
}

.counter {
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.9);
}

footer {
    color: var(--white);
    font-size: .9rem;
    line-height: 1.6rem;
}

footer img {
    filter: brightness(0) invert(1);
}

ul.footer-nav-links {
    padding-left: 0;
    list-style: none;
}

ul.footer-nav-links a {
    text-decoration: none;
    color: var(--white);
    transition: all 400ms ease-out;
    outline: 0 !important;
}

ul.footer-nav-links li a:hover {
    color: var(--red);
    text-decoration: underline !important;
}

.footer-area {
    background: var(--graphite);
    padding: 100px 0;
}

.copyright-area {
    background: #1f1f1f;
    padding: 35px 0;
}

.go-top {
    position: fixed;
    background-color: var(--red);
    color: white;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    right: 5vw;
    bottom: 5vh;
}

.go-top:hover {
    color: white;
    background-color: hsl(0, 99%, 46%);
}

.bg-dark {
    background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
    background-image: linear-gradient(-20deg, #2b5876 0%, #4e4376 100%);
    background: linear-gradient(to bottom, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(255, 255, 255, 0.40) 0%, rgba(0, 0, 0, 0.25) 200%);
    background-image: linear-gradient(to right, var(--graphite) 0%, black 100%);
}

section.banner * {
    font-size: 4rem;
}

section.banner .text-danger {
    color: var(--red) !important;
    font-family: 'Oswald', sans-serif;
    font-size: 6rem !important;
}

.partners-section .react-multi-carousel-track {
    display: flex;
    align-items: center;
}

.partners-section .react-multi-carousel-track .react-multi-carousel-item {
    display: flex;
    justify-content: center;
}

.partners-section .react-multi-carousel-track .react-multi-carousel-item img {
    max-width: 200px !important;
    height: max-content;
}

.partners-section .react-multi-carousel-list {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    padding-bottom: 2rem;
}

.indicators-section {
    background-image: linear-gradient(to right, var(--graphite) 0%, black 100%);
    border-radius: 0 50px 0 50px;
}

.form-control, .form-control:active, .form-control:focus {
    font-size: 1.5rem;
    background: transparent;
    border: none;
    border-radius: 0;
    margin-bottom: 3rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
    outline: none;
    box-shadow: none;
    caret-color: #fff;
    color: #fff;
    font-weight: 300 !important;
}

.form-control::placeholder {
    color: #fff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: none !important;
}

@media (max-width: 480px) {
    html, body {
        overflow-x: hidden;
    }

    section.banner * {
        font-size: 2rem !important;
        text-align: center;
    }

    section.banner .text-danger {
        font-size: 3rem !important;
    }

    .px-10 {
        padding: 0 1rem;
    }

    .py-10 {
        padding: 1rem 0;
    }
}
